import React from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import appwrite from "../appwrite";
import { stringToHash, validateEmail } from "../utils";
import Loading from "../components/Loading";
import { format } from "date-fns";
import { Redirect } from "react-router-dom";
import logo from "../hiltonLogo.png";
import linkIcon from "../linkIcon.png";

export default function Conference({
  setSnackBar,
  roomId,
  setRoomState,
  setLoggedIn,
  loggedIn,
}) {
  const [roomData, setRoomData] = React.useState(null);
  const [pwProtected, setPwProtected] = React.useState(true);
  const [pw, setPW] = React.useState("");
  const [showLogin, setShowLogin] = React.useState(false);
  const [loginData, setLoginData] = React.useState({ email: "", pw: "" });
  const [showSidebar, setShowSidebar] = React.useState(true);

  React.useEffect(() => {
    async function fetchRoom() {
      try {
        let roomData = await appwrite.database.getDocument(
          "5fd88b12e71ef",
          roomId
        );
        if (
          roomData.password.length < 1 ||
          roomData.password === "0" ||
          !pwProtected
        ) {
          setPwProtected(false);
          setRoomData(roomData);
        } else {
          setRoomData("waiting on pw");
        }
      } catch (error) {
        console.log(error);
        setRoomData(false);
      }
    }
    fetchRoom();
  }, [roomId]);

  const handlePW = async (event) => {
    event.preventDefault();
    if (pw === "") return;
    let hashPW = stringToHash(pw).toString();
    try {
      let docs = await appwrite.database.listDocuments("5fd88b12e71ef", [
        `password=${hashPW}`,
      ]);
      const room = docs.documents.find((room) => room.$id === roomId);
      if (room && room.password === hashPW) {
        setRoomData(room);
        setPwProtected(false);
      }
    } catch (error) {
      setSnackBar({ open: true, text: error.message, type: "error" });
      console.log(error);
    }
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    if (loginData.email === "" || loginData.password === "") return;
    if (!validateEmail(loginData.email)) {
      setSnackBar({
        open: true,
        type: "error",
        text: "Please enter a valid Email Address.",
      });
      return;
    }
    try {
      let session = await appwrite.account.createSession(
        loginData.email,
        loginData.pw
      );
      let user = await appwrite.account.get();
      if (user.$id === roomData.owner) {
        setSnackBar({
          open: true,
          type: "success",
          text: "Login successful.",
        });
        setLoggedIn(user);
      } else {
        setSnackBar({
          open: true,
          type: "error",
          text: "User not host of this conference.",
        });
      }
    } catch (error) {
      setSnackBar({
        open: true,
        type: "error",
        text: error.message,
      });
      console.log(error);
    }
  };

  const closeRoom = async () => {
    try {
      await appwrite.database.updateDocument(
        "5fd88b12e71ef",
        roomId,
        {
          status: "closed",
        },
        ["*"],
        ["*"]
      );
      setRoomData({ ...roomData, status: "closed" });
      setSnackBar({
        open: true,
        type: "success",
        text: "Room closed",
      });
    } catch (error) {
      console.log(error);
    }
  };

  return roomData === null ? (
    <Loading />
  ) : roomData === false ? (
    <div>
      <h1>Error</h1>
    </div>
  ) : pwProtected ? (
    <div>
      <h3 style={{ textAlign: "center", marginBottom: 25 }}>
        Room ist password protected
      </h3>
      <form
        style={{ display: "flex", flexDirection: "column" }}
        onSubmit={handlePW}
      >
        <TextField
          style={{ marginBottom: 15 }}
          required
          id="roomPassword"
          label="Room Password"
          variant="outlined"
          type="password"
          onChange={(event) => {
            setPW(event.target.value);
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Button
            type="submit"
            size="large"
            variant="contained"
            color="primary"
            style={{ marginBottom: 10 }}
          >
            Enter
          </Button>
        </div>
      </form>
    </div>
  ) : (
    <div
      style={{
        minHeight: "100vh",
        width: "100%",
        display: "flex",
      }}
    >
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          padding: "0 21px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            padding: "25px 0",
          }}
        >
          <img style={{ height: 95 }} alt="logo" src={logo} />
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#F7F7F7",
            width: "100%",
          }}
        >
          <iframe
            title={roomId}
            allow="camera; microphone; fullscreen; display-capture"
            src={`https://jitsi.infinite-tools.com/${roomId}`}
            style={{ height: "100%", width: "100%", border: "0px" }}
          ></iframe>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            padding: "25px 0",
          }}
        >
          <div style={{ flex: 1 }}>
            <Button variant="contained" color="primary" size="small">
              Edit
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: 20,
              fontSize: 14,
            }}
            onClick={() => {
              alert("this would copy the link to clipboard (it does not, yet)");
            }}
          >
            <img
              alt="link icon"
              src={linkIcon}
              style={{ marginRight: 5, height: 16 }}
            />
            <p
              style={{
                display: "flex",
                alignItems: "center",
                margin: 0,
                lineHeight: 1,
                color: "#254D92",
                cursor: "pointer",
              }}
            >
              copy link<span style={{ margin: "0 8px" }}>|</span>
              {`linkToSite.com/${roomId}`}
            </p>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <TextField
              style={{ marginRight: 8 }}
              id="emailInvite"
              label="Email Address"
              variant="outlined"
              required
              size="small"
              onChange={(event) => {
                console.log("blabla");
              }}
            />
            <Button
              onClick={() => {
                alert("this would send an email with the link");
              }}
              variant="contained"
              color="primary"
            >
              Send Invite
            </Button>
          </div>
        </div>
      </div>
      {showSidebar ? (
        <div
          style={{
            flex: 0.2,
            backgroundColor: "#F7F7F7",
            padding: 20,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ flex: 1 }}>
            <p
              onClick={() => {
                setShowSidebar(false);
              }}
              style={{
                display: "flex",
                alignItems: "center",
                lineHeight: 1,
                fontSize: 14,
                color: "#254D92",
                cursor: "pointer",
                marginBottom: 15,
              }}
            >
              <span
                style={{
                  width: 6,
                  height: 6,
                  transform: "rotate(135deg) translateY(1px)",
                  borderLeft: "1px solid #254D92",
                  borderTop: "1px solid #254D92",
                  marginRight: 8,
                }}
              ></span>
              hide sidebar
            </p>
            <div style={{ display: "none" }}>
              <h3>
                Welcome to {roomData.name !== "" ? roomData.name : roomId}
              </h3>
              <p>
                Room will be available until
                <br />
                <span>
                  {format(
                    new Date(roomData.started + 1000 * 60 * 60 * 24),
                    "dd.MM.yyyy - HH:mm"
                  )}
                </span>
              </p>
            </div>
            <div
              style={{
                backgroundColor: "#fff",
                borderRadius: 5,
                padding: 12,
                marginBottom: 15,
              }}
            >
              <h3
                style={{
                  margin: 0,
                  color: "#254D92",
                  fontWeight: 400,
                  marginBottom: 15,
                }}
              >
                Agenda
              </h3>
              <ol style={{ margin: 0, paddingLeft: 15 }}>
                <li style={{ marginBottom: 10, fontSize: 14 }}>
                  Topic of Conference
                </li>
                <li style={{ marginBottom: 10, fontSize: 14 }}>
                  Topic of Conference
                </li>
                <li style={{ marginBottom: 10, fontSize: 14 }}>
                  Topic of Conference
                </li>
                <li style={{ marginBottom: 10, fontSize: 14 }}>
                  Topic of Conference
                </li>
              </ol>
            </div>
            <div
              style={{
                backgroundColor: "#fff",
                borderRadius: 5,
                padding: 12,
                marginBottom: 15,
              }}
            >
              <h3
                style={{
                  margin: 0,
                  color: "#254D92",
                  fontWeight: 400,
                  marginBottom: 15,
                }}
              >
                Downloads
              </h3>
              <p style={{ marginBottom: 10, fontSize: 14 }}>Filename</p>
              <p style={{ marginBottom: 10, fontSize: 14 }}>Filename</p>
              <p style={{ marginBottom: 10, fontSize: 14 }}>Filename</p>
              <p style={{ marginBottom: 10, fontSize: 14 }}>Filename</p>
            </div>
            <div
              style={{
                backgroundColor: "#fff",
                borderRadius: 5,
                padding: 12,
                marginBottom: 15,
              }}
            >
              <h3
                style={{
                  margin: 0,
                  color: "#254D92",
                  fontWeight: 400,
                  marginBottom: 15,
                }}
              >
                Chat
              </h3>
            </div>
          </div>
          <div>
            {loggedIn ? (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <p>Logged in as host: {loggedIn.email}</p>
                {roomData.status !== "closed" && (
                  <>
                    <Button
                      size="small"
                      variant="contained"
                      color="secondary"
                      onClick={closeRoom}
                      style={{ marginBottom: 10 }}
                    >
                      Close Room
                    </Button>
                    <span style={{ fontSize: 12, opacity: 0.8 }}>
                      Attention: "Closing" does not kick current participants
                      out. It just prevents new people from joining.
                    </span>
                  </>
                )}
              </div>
            ) : (
              <div style={{ display: "flex", flexDirection: "column" }}>
                {!showLogin ? (
                  <Button
                    onClick={() => {
                      setShowLogin(true);
                    }}
                    size="small"
                    variant="contained"
                    color="primary"
                  >
                    Login as Host
                  </Button>
                ) : (
                  <form
                    style={{ display: "flex", flexDirection: "column" }}
                    onSubmit={handleLogin}
                  >
                    <TextField
                      style={{ marginBottom: 15 }}
                      required
                      id="email"
                      label="Email Address"
                      variant="outlined"
                      onChange={(event) => {
                        setLoginData({
                          ...loginData,
                          email: event.target.value,
                        });
                      }}
                    />
                    <TextField
                      style={{ marginBottom: 15 }}
                      required
                      id="password"
                      type="password"
                      label="Password"
                      variant="outlined"
                      onChange={(event) => {
                        setLoginData({ ...loginData, pw: event.target.value });
                      }}
                    />
                    <Button
                      type="submit"
                      size="small"
                      variant="contained"
                      color="primary"
                      style={{ marginBottom: 15 }}
                    >
                      Login
                    </Button>
                    <Button
                      size="small"
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        setShowLogin(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </form>
                )}
              </div>
            )}
          </div>
        </div>
      ) : (
        <p
          onClick={() => {
            setShowSidebar(true);
          }}
          style={{
            display: "flex",
            alignItems: "center",
            lineHeight: 1,
            fontSize: 14,
            color: "#254D92",
            cursor: "pointer",
            position: "absolute",
            right: -1,
            top: 20,
            backgroundColor: "#fff",
            borderRadius: "5px 0 0 5px",
            padding: 10,
            border: "1px solid #254D92",
            boxShadow: "0 0 5px rgba(0,0,0,0.2)",
          }}
        >
          <span
            style={{
              width: 6,
              height: 6,
              transform: "rotate(-45deg) translateY(-1px)",
              borderLeft: "1px solid #254D92",
              borderTop: "1px solid #254D92",
              marginRight: 8,
            }}
          ></span>
          show sidebar
        </p>
      )}
    </div>
  );
}

import React from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import appwrite from "../appwrite";
import { stringToHash, validateEmail } from "../utils";
import { format } from "date-fns";
import logo from "../hiltonLogo.png";

export default function HostRegister({
  roomId,
  setSnackBar,
  setRoomState,
  setLoggedIn,
  loggedIn,
}) {
  const [formData, setFormData] = React.useState({
    email: "",
    pw: "",
    pwc: "",
  });
  const [setupFormData, setSetupFormData] = React.useState({
    name: "",
    password: "",
  });
  const [sent, setSent] = React.useState(false);
  const [login, setLogin] = React.useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!login && formData.pw !== formData.pwc) {
      setSnackBar({ open: true, type: "error", text: "Passwords don't match" });
      return;
    }
    if (!validateEmail(formData.email)) {
      setSnackBar({
        open: true,
        type: "error",
        text: "Please enter a valid Email Address.",
      });
      return;
    }
    try {
      let user;
      if (login) {
        let session = await appwrite.account.createSession(
          formData.email,
          formData.pw
        );
        user = await appwrite.account.get();
        setSnackBar({
          open: true,
          type: "success",
          text: "Login successful.",
        });
        setLoggedIn(user);
      } else {
        user = await appwrite.account.create(formData.email, formData.pw);
        setSent(true);
        setSnackBar({
          open: true,
          type: "success",
          text: "Registration complete.",
        });
      }
    } catch (error) {
      setSnackBar({
        open: true,
        type: "error",
        text: error.message,
      });
      console.log(error);
    }
  };

  const setUpRoom = async (event) => {
    event.preventDefault();
    const roomData = {
      name: setupFormData.name,
      password: stringToHash(setupFormData.password).toString(),
      owner: loggedIn.$id,
      status: "occupied",
      started: Date.now(),
    };
    try {
      await appwrite.database.updateDocument(
        "5fd88b12e71ef",
        roomId,
        roomData,
        ["*"],
        ["*"]
      );
      setSnackBar({
        open: true,
        type: "success",
        text: "Room setup. Happy conferencing.",
      });
      setRoomState("occupied");
    } catch (error) {
      setSnackBar({
        open: true,
        type: "error",
        text: error.message,
      });
      console.log(error);
    }
  };

  return (
    <div style={{ width: 400 }}>
      <div style={{ textAlign: "center", marginBottom: 0 }}>
        <img alt="logo" width={200} src={logo} />
        <p style={{ textAlign: "center" }}>Conferencing Services</p>
      </div>
      {loggedIn ? (
        <div>
          <h3 style={{ textAlign: "center", marginBottom: 25 }}>
            Room Setup for {roomId}
          </h3>
          <form
            style={{ display: "flex", flexDirection: "column" }}
            onSubmit={setUpRoom}
          >
            <TextField
              style={{ marginBottom: 15 }}
              id="roomName"
              label="Room Name (optional)"
              variant="outlined"
              onChange={(event) => {
                setSetupFormData({
                  ...setupFormData,
                  name: event.target.value,
                });
              }}
            />
            <TextField
              style={{ marginBottom: 15 }}
              id="roomPassword"
              label="Room Password (optional)"
              variant="outlined"
              onChange={(event) => {
                setSetupFormData({
                  ...setupFormData,
                  password: event.target.value,
                });
              }}
            />
            <TextField
              style={{ marginBottom: 10 }}
              id="roomLink"
              label="The link to your room"
              variant="outlined"
              readOnly
              value={`linkToSite.com/${roomId}`}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {setupFormData.password === "" && (
                <p style={{ textAlign: "center", color: "red" }}>
                  <strong>Warning:</strong>
                  <br />
                  Without a password, everyone with the link can join your
                  conference.
                </p>
              )}
              <Button
                type="submit"
                size="large"
                variant="contained"
                color="primary"
                style={{ marginBottom: 10 }}
              >
                Open Room
              </Button>
              <Button
                size="large"
                variant="contained"
                color="secondary"
                onClick={() => {
                  setLoggedIn(null);
                }}
              >
                Logout and Cancel
              </Button>
            </div>
          </form>
        </div>
      ) : login ? (
        <>
          <p style={{ textAlign: "center" }}>
            Room {roomId} is not reserved yet. To setup your room and start a
            conference, please login as host.
          </p>
          <form
            style={{ display: "flex", flexDirection: "column" }}
            onSubmit={handleSubmit}
          >
            <TextField
              style={{ marginBottom: 15 }}
              required
              id="email"
              label="Email Address"
              variant="outlined"
              onChange={(event) => {
                setFormData({ ...formData, email: event.target.value });
              }}
            />
            <TextField
              style={{ marginBottom: 15 }}
              required
              id="password"
              type="password"
              label="Password"
              variant="outlined"
              onChange={(event) => {
                setFormData({ ...formData, pw: event.target.value });
              }}
            />
            <Button
              type="submit"
              size="large"
              variant="contained"
              color="primary"
            >
              Login
            </Button>
          </form>
          <p style={{ textAlign: "center" }}>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                setLogin(false);
              }}
            >
              I'm not registered, I want to register
            </span>
          </p>
        </>
      ) : !sent ? (
        <>
          <p style={{ textAlign: "center" }}>
            Room {roomId} is not reserved yet. To setup your room and start a
            conference, please register as host.
          </p>
          <form
            style={{ display: "flex", flexDirection: "column" }}
            onSubmit={handleSubmit}
          >
            <TextField
              style={{ marginBottom: 15 }}
              required
              id="email"
              label="Email Address"
              variant="outlined"
              onChange={(event) => {
                setFormData({ ...formData, email: event.target.value });
              }}
            />
            <TextField
              style={{ marginBottom: 15 }}
              required
              id="password"
              type="password"
              label="Password"
              variant="outlined"
              onChange={(event) => {
                setFormData({ ...formData, pw: event.target.value });
              }}
            />
            <TextField
              style={{
                marginBottom: 15,
                backgroundColor:
                  formData.pw !== formData.pwc
                    ? "rgba(255,0,0,0.2)"
                    : "transparent",
              }}
              required
              type="password"
              id="password-confirm"
              label="Confirm Password"
              variant="outlined"
              onChange={(event) => {
                setFormData({ ...formData, pwc: event.target.value });
              }}
            />
            <Button
              type="submit"
              size="large"
              variant="contained"
              color="primary"
            >
              Register
            </Button>
          </form>
          <p style={{ textAlign: "center" }}>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                setLogin(true);
              }}
            >
              I'm already registered, I want to login
            </span>
          </p>
        </>
      ) : (
        <div>
          <p style={{ textAlign: "center" }}>
            Usually the user would have to confirm her email address by clicking
            a link. We're skipping that for now and jump straight to the login
            and room setup.
          </p>
          <p style={{ textAlign: "center" }}>
            <span
              style={{ cursor: "pointer", textDecoration: "underline" }}
              onClick={() => {
                setLogin(true);
              }}
            >
              Click here to proceed
            </span>
          </p>
        </div>
      )}
    </div>
  );
}

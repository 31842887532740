import "./App.css";
import React from "react";
import ChooseRoom from "./screens/ChooseRoom";
import Room from "./screens/Room";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#254D92",
    },
    secondary: {
      main: "#ff0000",
    },

    contrastThreshold: 3,

    tonalOffset: 0.2,
  },
});
function App() {
  const [snackBar, setSnackBar] = React.useState({
    open: false,
    type: "success",
    text: "",
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBar({ ...snackBar, open: false });
  };
  return (
    <ThemeProvider theme={theme}>
      <div className="WrapAll">
        <Router>
          <Switch>
            <Route exact path="/">
              <ChooseRoom setSnackBar={setSnackBar} />
            </Route>
            <Route exact path="/room">
              <Redirect to="/" />
            </Route>
            <Route path="/room/:roomId">
              <Room setSnackBar={setSnackBar} />
            </Route>
          </Switch>
        </Router>
      </div>
      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={snackBar.type}>
          {snackBar.text}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
}

export default App;

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

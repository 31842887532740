import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useParams,
  NavLink,
} from "react-router-dom";
import Loading from "../components/Loading";
import HostRegister from "../components/HostRegister";
import Conference from "../components/Conference";
import appwrite from "../appwrite";

export default function Room({ setSnackBar }) {
  const { roomId } = useParams();
  const [roomState, setRoomState] = React.useState(null);
  const [loggedIn, setLoggedIn] = React.useState(null);

  const verifyRoom = async (roomId) => {
    let promise = appwrite.database.getDocument("5fd88b12e71ef", roomId);
    promise.then(
      function (response) {
        setRoomState(response.status); // room in setup
      },
      function (error) {
        setRoomState(false);
        console.log(error); // Failure
      }
    );
  };
  React.useEffect(() => {
    verifyRoom(roomId);
  }, [roomId]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      {roomState === null ? (
        <Loading />
      ) : roomState === false ? (
        <NavLink to="/">No room found... go back!</NavLink>
      ) : roomState === "free" ? (
        <HostRegister
          setSnackBar={setSnackBar}
          roomId={roomId}
          setRoomState={setRoomState}
          setLoggedIn={setLoggedIn}
          loggedIn={loggedIn}
        />
      ) : roomState === "occupied" ? (
        <Conference
          setSnackBar={setSnackBar}
          roomId={roomId}
          setRoomState={setRoomState}
          setLoggedIn={setLoggedIn}
          loggedIn={loggedIn}
        />
      ) : (
        <NavLink to="/">No room found... go back!</NavLink>
      )}
    </div>
  );
}

import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useParams,
} from "react-router-dom";
import appwrite from "../appwrite";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import logo from "../hiltonLogo.png";

export default function ChooseRoom() {
  const [roomId, setRoomId] = React.useState("");
  const [redirect, setRedirect] = React.useState(null);

  const checkRoomId = async () => {
    try {
      let doc = await appwrite.database.getDocument("5fd88b12e71ef", roomId);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    if (roomId === "") return;
    const check = await checkRoomId();
    if (check) {
      setRedirect(roomId);
    } else {
      alert("wrong room id");
      setRoomId("");
    }
  };
  return redirect ? (
    <Redirect to={`/room/${redirect}`} />
  ) : (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <div style={{ width: 400 }}>
        <div style={{ textAlign: "center", marginBottom: 0 }}>
          <img alt="logo" width={200} src={logo} />
          <p style={{ textAlign: "center" }}>Conferencing Services</p>
        </div>
        <div>
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            onSubmit={onSubmit}
          >
            <TextField
              style={{ marginBottom: 15 }}
              required
              id="roomId"
              label="Enter your code"
              variant="outlined"
              onChange={(event) => {
                setRoomId(event.target.value);
              }}
            />
            <Button
              type="submit"
              size="large"
              variant="contained"
              color="primary"
            >
              Enter
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
}
